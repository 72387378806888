import React, { useState } from 'react';

// 隨機祝福語陣列（中文）
const messages = [
  "你是我心中最甜蜜的牽掛，情人節快樂！",
  "親愛的，你真的好美",
  "你讓我的世界都停下來了。",
  "你的笑容讓我心跳加速。",
  "你是我的陽光，照亮了我的世界。",
  "和你在一起是我最喜歡的時光。",
  "你的笑容，能點亮最黑暗的日子。",
  "每一刻和你在一起都像是在做夢。",
  "你的存在，讓一切都變得更美好。"
];

const LoveCard = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [randomMessage, setRandomMessage] = useState('');

  // 隨機選擇一個祝福語
  const getRandomMessage = () => {
    const randomIndex = Math.floor(Math.random() * messages.length);
    setRandomMessage(messages[randomIndex]); // 顯示隨機選中的祝福語
  };

  const toggleEnvelope = () => {
    setIsOpen(!isOpen); // 切換開關信封狀態
    if (!isOpen) {
      getRandomMessage(); // 在打開信封時隨機選擇祝福語
    }
  };

  return (
    <div className="envelope-wrapper">
      <div id="envelope" className={isOpen ? 'open' : 'close'}>
        <div className="front flap"></div>
        <div className="front pocket"></div>
        <div className="letter">
          <div className="words line1">{randomMessage}</div> {/* 顯示隨機的祝福語 */}
          <div className="words line2"></div> 
          <div className="words line3"></div>
          <div className="words line4"></div>
        </div>
        <div className="hearts">
          <div className="heart a1"></div>
          <div className="heart a2"></div>
          {/* <div className="heart a3"></div> */}
          <div className="heart a4"></div>
        </div>
      </div>

      <div className="reset">
        <button onClick={toggleEnvelope}>
          {isOpen ? '關閉信封' : '開啟信封'}
        </button>
      </div>
    </div>
  );
};

export default LoveCard;
